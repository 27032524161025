import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Footer } from '../Footer'
import NavBarStaticPage from '../NavBar/NavBarStaticPage'

const Layout = styled.div`
  margin: 72px 0 0;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`
interface Props {
  children: React.ReactNode
  title: string
}
const LayoutStaticPage = (props: Props) => {
  const { children, title } = props
  return (
    <Layout id="layout">
      <Helmet>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>
      <NavBarStaticPage />
      {children}
      <Footer />
    </Layout>
  )
}

export default LayoutStaticPage
