import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'
import { graphql } from 'gatsby'
import LayoutStaticPage from '../components/Layout/LayoutStaticPage'
import withPageTransition from '../components/withPageTransition'
import { StaticPage } from '../types/PgPages'
import { useStaticPageTemplate } from '../hooks/useStaticPageTemplate'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.medium};
  min-height: calc(100vh - 234px);
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 206px);
  }
  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 257px);
  }
`
const Container = styled.div`
  max-width: 794px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 40px;
  h2 {
    color: ${(props) => props.theme.colors.success};
    font-size: 34px;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
  h3 {
    color: ${(props) => props.theme.colors.success};
    font-size: 24px;
    margin-bottom: 0.5em;
  }
  h4 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 17px;
    letter-spacing: -0.24px;
    margin-top: 0;
    line-height: 1.4;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  ul {
    padding-inline-start: 16px;
  }
  li {
    padding-bottom: 16px;
  }

  .media-info {
    display: flex;
    align-items: center;
    .image {
      margin-right: 10px;
      width: 100px;
    }
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.success};
    letter-spacing: -0.25px;
    display: block;
  }

  .description {
    font-size: 13px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
    letter-spacing: -0.06px;
    border: 0;
    display: block;
  }
  .columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, 225px);
    gap: 1em;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .Returnability__ {
    &go-back {
      svg {
        width: 24px;
        height: 24px;
        fill: ${(props) => props.theme.colors.black};
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    padding: 50px 100px;
  }
  @media (${breakpoints.desktop.min}) {
    padding: 50px 100px;
  }
`

function ReturnabilityPage(pageData: StaticPage) {
  const { pageTitle, title, preTitle, description, displayContent } = useStaticPageTemplate(pageData)

  return (
    <LayoutStaticPage title={pageTitle}>
      <Wrapper>
        <Container>
          <h4>{preTitle}</h4>
          <h2>{title}</h2>
          <p>{description}</p>
          {displayContent()}
        </Container>
      </Wrapper>
    </LayoutStaticPage>
  )
}

export default withPageTransition(ReturnabilityPage)

export const query = graphql`
  query ReturnabilityQuery($returnabilityId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $returnabilityId } }) {
      edges {
        node {
          id
          title
          template {
            ... on ContentfulTmStaticPage {
              id
              name
              title
              preTitle
              description
              content {
                json
              }
              customHtml {
                customHtml
              }
            }
          }
        }
      }
    }
  }
`
